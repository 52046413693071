export class ShortUrlModel {
    id?: number
    code?: string
    longUrl?: string
    shortUrl?: string

    static fromJson(json: ShortUrlModel): ShortUrlModel {
        const data = Object.assign(new ShortUrlModel(), json)
        return data
    }

}