
import React, { FormEventHandler, KeyboardEventHandler, RefObject } from "react";
import { HomeProvider, useHomeProvider } from "./provider";
import './index.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";

function HomePage() {
    return (
        <HomeProvider>
            <View />
        </HomeProvider>
    );
}

function View() {
    const provider = useHomeProvider()

    function _onSubmit() {
        provider.onSubmit()
    }
    function _onKeyHandle(event: React.KeyboardEvent<HTMLInputElement>): void {
        if (event.key === 'Enter') {
            provider.onSubmit()
        }
    }

    function _onPaste(e: React.ClipboardEvent<HTMLInputElement>) {
        setTimeout(_onSubmit, 200)
    }

    return (
        <div style={{
            textAlign: 'center'
        }}>
            <p style={{
                fontSize: 56,
                fontWeight: "bold",
            }} className="primary">SHORT URL</p>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '16px'
                }}>
                <input
                    type="text"
                    ref={provider.inputRef as RefObject<HTMLInputElement>}
                    onKeyDown={_onKeyHandle}
                    onPaste={_onPaste}
                    style={{ minWidth: 320 }}
                    placeholder="Nhập Url" />
                <p style={{ width: 16 }}></p>
                <button onClick={_onSubmit}>Shorten</button>
            </div>
            {
                provider.error && (
                    <div className="error">
                        {provider.error}
                    </div>
                )
            }
            {

                provider.shortUrl?.shortUrl && (
                    <div>
                        <div className="result-container">
                            <div className="content">
                                Short Url: <a href={`${provider.shortUrl?.shortUrl}`} target="_blank" style={{ marginLeft: 4 }}>{provider.shortUrl?.shortUrl}</a>
                                <span className="copy" style={{ marginLeft: 16 }} onClick={provider.onCopy}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z" /></svg>
                                </span>
                            </div>
                        </div>
                        <div style={{ marginTop: 32 }}>
                            <span className="outline-button" onClick={provider.onReset}>Url Khác</span>
                        </div>

                    </div>
                )
            }
            <ToastContainer />

        </div>
    );
}

export default HomePage
