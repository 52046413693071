
import { ReactElement, RefObject, createContext, useContext, useRef, useState } from "react";
import { ShortUrlModel } from "../../model/short-url-model";
import { ApiPath, BASE_URL } from "../../common/constant";
import { toast } from "react-toastify";

const HomeContext = createContext<HomeType>(null!)

interface HomeType {
	shortUrl: ShortUrlModel | undefined
	setShortUrl: (shortUrl: ShortUrlModel | undefined) => void
	error: string | undefined
	setError: (error: string | undefined) => void
	inputRef: RefObject<HTMLInputElement | undefined>
	onSubmit: () => any
	onCopy: () => any
	onReset: () => any
}

export function useHomeProvider() {
	return useContext(HomeContext)
}


export function HomeProvider({ children }: { children: ReactElement }) {
	const [shortUrl, setShortUrl] = useState<ShortUrlModel | undefined>()
	const [error, setError] = useState<string | undefined>()
	const inputRef = useRef<HTMLInputElement | undefined>()

	async function onSubmit() {
		var longUrl = inputRef.current?.value.trim()

		if (!longUrl) {
			setError('Vui lòng nhập url!')
			return
		}

		if (!longUrl.startsWith('http')) {
			longUrl = 'https://' + longUrl
		}
		inputRef.current!.value = longUrl ?? ''
		try {
			const result = await fetch(BASE_URL + ApiPath.generate, {
				method: 'POST',
				headers: {
					'content-type': 'application/json'
				},
				body: JSON.stringify({
					longUrl: longUrl
				})
			}).then((result) => result.json())
			if (result.error) {
				setError(result.message ?? 'Đã có lỗi xảy ra. Vui lòng thử lại sau!')
				return
			}

			const shortUrl = ShortUrlModel.fromJson(result)
			setShortUrl(shortUrl)
			setError(undefined)
		} catch (result) {
			console.log(result);
		}

	}

	function onCopy() {
		const content = shortUrl?.shortUrl
		if (!content) return
		navigator.clipboard.writeText(content)
		toast.success("Đã copy: " + content);
	}

	function onReset() {
		inputRef.current!.value = ''
		inputRef.current?.focus()
		setError(undefined)
		setShortUrl(undefined)
	}
	const value = {
		shortUrl,
		setShortUrl,
		error,
		setError,
		inputRef,
		onSubmit,
		onCopy,
		onReset,
	}
	return (
		<HomeContext.Provider value={value}>
			{children}
		</HomeContext.Provider>
	)
}
